import React, {FC, useState} from "react";
import { useForm} from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
    lang: string;
}

interface IFormInputs {
    fullName: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
    fromDate?: any;
    toDate?: any;
}

export const ContactForm:FC<Props> = ({lang}) => {
    const [contact, setContact] = useState({});
    const [valid, setValid] = useState(false);
    const [duplicateMessage, setDuplicateMessage] = useState(false);
    const phoneRegExp = /(\+[\d\s\-])|[\d\s\-]/g

    const schema = Yup.object().shape({
        fullName: Yup
            .string()
            .matches(/[\w\s-]+/, {
                message: "Name is not valid",
                excludeEmptyString: true
            })
            .required("First name empty, Please fill in your First name"),
        email: Yup
            .string()
            .email("Email is not a valid email, please make sure you entered correct")
            .required("Email is empty, Please fill in your email so that we can contact you"),
        phone: Yup
            .string()
            .matches(phoneRegExp, "Not a Valid Phone Number")
            .required(),
        subject: Yup
            .string()
            .matches(/^(?!placeholder).*$/, {
                message: lang === "en" ? "Option not Selected Please select one of the options above" : "Subject er ikke valgt, veldigst velg en av valgene"
            })
            .required(""),
        message: Yup
            .string()
            .min(10, "the message is to short, please enter a more descriptive message")
            .required("Please enter a message"),
        fromDate: Yup
            .date()
            .notRequired(),
        toDate: Yup
            .date()
            .notRequired()
    })
    const { register, handleSubmit, formState: { errors }, getValues, reset, watch} = useForm<IFormInputs>({
        resolver: yupResolver(schema)
    });

    const formData = watch();

    function _sendForm(data:any) {
        console.log("comparing", JSON.stringify(contact) === JSON.stringify(data));
        reset();
        if (JSON.stringify(contact) !== JSON.stringify(data)) { //work around due to shallow compare
            const formData = {
                fullName: data.fullName,
                email: data.email,
                subject: data.subject,
                phone: data.phone,
                message: data.message,
                fromDate: data.fromDate,
                toDate: data.toDate
            }
            setContact(formData);

            setValid(true);
            // @ts-ignore
            window.emailjs.send('service_vuad1fu', "template_zmijxmj", formData)
                .then(() => console.log('Email Successfully sent!'))
                .catch((err:string) => console.error("email not sent, " + err));
            console.log("accepted");
            return true;
        } else {
            setDuplicateMessage(true);
            return false
        }
    }

    return (
        <form className="contact__form col-l-8 col-12" onSubmit={handleSubmit((e) => _sendForm(e))}>
            <div className="form__firstName form__group col-12">
                <label className="form-firstName__label form__label" htmlFor="fullName">{lang === "en" ? "Name" : "Navn"}</label>
                <input className="form-firstName__input form__item form__input" type="text" placeholder="Ola Nordmann" {...register("fullName")} />
                {errors.fullName && <p className="form__error">{errors.fullName.message}</p>}
            </div>
            <div className="form__section row">
                <div className="form__email form__group col-6 col-s-12">
                    <label className="form-email__label form__label" htmlFor="email">{lang === "en" ? "Email address" : "Epost adresse"}</label>
                    <input className="form-email__input form__item form__input" type="email" placeholder="example@example.com" {...register("email")}/>
                    {errors.email && <p className="form__error">{errors.email.message}</p>}
                </div>
                <div className="form__email form__group col-6 col-s-12">
                    <label className="form-email__label form__label" htmlFor="phone">{lang === "en" ? "Phone number" : "Telefonnummer"}</label>
                    <input className="form-email__input form__item form__input" type="text" placeholder="+47 123 45 678" {...register("phone")}/>
                    {errors.phone && <p className="form__error">{errors.phone.message}</p>}
                </div>
            </div>
            <div className="form__section row">
                <div className="form__context form__group col-12">
                    <label className="form-context__label form__label" htmlFor="context">{lang === "en" ? "Subject" : "Emne"}</label>
                    <select className="form-context__input form__item form__select" {...register("subject")}>
                        <option className="form__option" value="placeholder" hidden defaultChecked>{lang === "en" ? "Please select one of the options..." : "Vennligst velg en av valgene..."}</option>
                        <option className="form__option" value="Bestilling">{lang === "en" ? "Ask for reservation" : "Bestilling av plass"}</option>
                        <option className="form__option" value="Avbestilling">{lang === "en" ? "Cancel reservation" : "Avbestilling"}</option>
                        <option className="form__option" value="Annet">{lang === "en" ? "Other" : "Annet"}</option>
                    </select>
                    {errors.subject && <p className="form__error">{errors.subject.message}</p>}
                </div>
            </div>
            {(formData.subject === "Bestilling" || formData.subject === "Avbestilling") && (
                <div className="form__section row">
                    <div className="form__fromDate form__group col-6 col-s-12">
                        <label className="form__fromDate__label form__label" htmlFor="fromDate">{lang === "en" ? "From" : "Fra"}</label>
                        <input className="form__fromDate__input form__item form__input" type="date" {...register("fromDate")}/>
                        {errors.fromDate && <p className="form__error">{errors.fromDate.message}</p>}
                    </div>
                    <div className="form__toDate form__group col-6 col-s-12">
                        <label className="form__toDate__label form__label" htmlFor="toDate">{lang === "en" ? "To" : "Til"}</label>
                        <input className="form__toDate__input form__item form__input" type="date" {...register("toDate")}/>
                        {errors.toDate && <p className="form__error">{errors.toDate.message}</p>}
                    </div>
                </div>
            )}
            <div className="form__message form__group col-12">
                <label className="form-message__label form__label" htmlFor="message">Message</label>
                <textarea className="form-message__input form__item form__textArea" placeholder={lang === "en" ? "Please enter a message..." : "Vennligst skriv din melding..."} {...register("message")}/>
                {errors.message && <p className="form__error">{errors.message.message}</p>}
            </div>
            <div className="form__group col-12">
                <button className="form__submit btn" type="submit" onClick={() => console.log(getValues())}>Send</button>
            </div>
            {duplicateMessage && (<p className="form__error">This message have already been sent</p>)}
            {valid && (
                <div className="contact__prompt">
                    <div className="contact-prompt">
                        <div className="contact-prompt__topBar">
                            <button className="contact-prompt__close" onClick={() => setValid(false)}>X</button>
                        </div>
                        <div className="contact-prompt__content">
                            <span className="prompt-content__main">{lang === "en" ? "Thank you for contacting us," : "Takk for at du kontaktet oss,"}</span>
                            <span className="prompt-content__sec">{lang === "en" ? ", we will reach back to you as soon as possible" : "Vi vil kontakte deg igjen så fort så mulig"}</span>
                        </div>
                        <button className="contact-prompt__ok" onClick={() => setValid(false)}>Ok</button>
                    </div>
                </div>
            )}
        </form>
    )
}