import React, {FC} from "react";
import {Link as Scroll} from 'react-scroll';

//images


interface Props {
    fixed?: boolean;
    lang: string;
}

export const Hero:FC<Props> = ({fixed, lang}) => {
    return (
        <div className={fixed ? "hero--fixed" : "hero"}>
            <div className="hero__components">
                <div className="hero__image">
                    <img src={"/bilder/hero1.jpg"} alt="Overlook of the Camping site"/>
                </div>
            </div>
            <div className="hero__content">
                <div className="hero__greet">
                    <h1 className="hero__primary h1">{lang === "en" ? "Welcome to Tysdal Camping" : "Velkommen til Tysdal Camping"}</h1>
                    <span className="hero__secondary h2">{lang === "en" ? "A calm and cozy camping" : "En rolig og hyggelig camping"}</span>
                </div>
                {/*<div className="hero__action">
                    <Scroll to="contact" smooth={true} className="hero__book">{lang === "en" ? "Contact us / book" : "Kontakt oss"}</Scroll>
                </div>*/}
            </div>
        </div>
    )
}