import React, {FC} from "react";

import {StayItem} from "./StayItem";
import {ExtraItem} from "./ExtrasItem";

//import of icons
import {faToilet, faShower} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


interface Props {
    currency: string;
    lang: string;
    stays: object[];
    extras: object[];
}

export const CardList:FC<Props> = ({currency, lang, stays, extras}) => {
    console.log(stays);
    return (
        <div className="prices">
            <div className="prices__stays">
                <h2 className="h3--center">{lang === "en" ? "Stays" : "Opphold"}</h2>
                <div className="cardList">
                    {stays.map((item:object, i:number) => {
                        return <StayItem key={i} item={item} lang={lang} currency={currency} className="stays"/>
                    })}
                </div>
            </div>
            <div className="prices__extras">
                <h2 className="h3--center">{lang === "en" ? "Extras" : "Annet"}</h2>
                <div className="cardList">
                    {extras.map((item:object, i:number) => {
                        return <ExtraItem key={i} lang={lang} item={item} currency={currency} className="extras"/>
                    })}
                </div>
            </div>
            <div className="prices__includes">
                <h2 className="h3--center">{lang === "en" ? "All stays includes, free" : "Opphold inkluderer, gratis"}</h2>
                {lang === "en" ? (
                    <div className="cardList">
                        <div className="card-item__includes">
                            <FontAwesomeIcon className="card-item__image" icon="toilet"/>
                            <span className="card-item__title h3">Toilets</span>
                            <span className="card-item__description">5 Toilets at service building</span>
                        </div>
                        <div className="card-item__includes">
                            <FontAwesomeIcon className="card-item__image" icon="shower"/>
                            <span className="card-item__title h3">Showers</span>
                            <span className="card-item__description">Free up till 5min per person</span>
                        </div>
                    </div>
                ) : (
                    <div className="cardList">
                        <div className="card-item__includes">
                            <FontAwesomeIcon className="card-item__image" icon="toilet"/>
                            <span className="card-item__title h3">Toaletter</span>
                            <span className="card-item__description">5 Toaletter ved servicebygg</span>
                        </div>
                        <div className="card-item__includes">
                            <FontAwesomeIcon className="card-item__image" icon="shower"/>
                            <span className="card-item__title h3">Dusj</span>
                            <span className="card-item__description">Gratis Dusj opp til 5min per pers.</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}