import React, {FC} from "react";

//React Responsive Carousel
import {Carousel} from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//images

interface Props {

}

export const carousel:FC<Props> = (props) => {
    return (
        <Carousel className="carousel" showArrows={true} autoPlay={true} stopOnHover={true} showStatus={false} infiniteLoop={true} >
            <div className="carousel__item">
                <img src={"/bilder/hero4.jpg"} alt="Overview of camping"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/hero2.jpg"} alt="Overview of camping"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/kidsA.jpg"} alt="Chess board"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/lekeplass.jpg"} alt="Swings"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/hero3.jpg"} alt="Overview of camping"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/beachoutlook.jpg"} alt="Overview of camping"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/cabin1.jpg"} alt="cabins"/>
            </div>
            <div className="carousel__item">
                <img src={"/bilder/cabin2.jpg"} alt="cabins"/>
            </div>
        </Carousel>
    )
}