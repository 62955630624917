import React, {FC, useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

//styles
import "./style/style.scss";

//components
import {Navigation} from "./components/structureComponents/Navigation/Navigation";
import {Footer} from "./components/structureComponents/Footer/Footer";
import {Home} from "./pages/Home";
import {LangSelect} from "./pages/LangSelect";
import useFetchFacilities from "./data/fetchFacilities";
import {useAppState} from "./context/global";


const App:FC = () => {
    const [appState]:any = useAppState()
    const [lang, setLang] = useState<string>("");
    const [currency, setCurrency] = useState("");

    function changeLang(language:string) {
        setLang(language);
        localStorage.setItem("language", language);
    }

    function changeCurrency(currency:string) {
        setCurrency(currency);
        localStorage.setItem("currency", currency);
    }

    function setPreference(lang:string, currency:string) {
        changeCurrency(currency);
        changeLang(lang);
    }

    useFetchFacilities();
    useEffect(() => {
        const lsLang = localStorage.getItem("language");
        const lsCurr = localStorage.getItem("currency");
        if (lsLang && lsCurr) {
            setLang(lsLang);
            setCurrency(lsCurr);
        }

    }, [])

    if (appState.stay) {
        return (
            <div className="App">
                {localStorage.getItem("language" || lang) ? (
                    <Router>
                        <Navigation lang={lang} setPref={setPreference} />
                        <Switch>
                            <Route path="/" exact component={() => <Home lang={lang} currency={currency}/>}/>
                        </Switch>
                        <Footer lang={lang}/>
                    </Router>
                ) : (
                    <Router>
                        <Route path="/" exact component={() => <LangSelect setPreference={setPreference}/>}/>
                    </Router>
                )}
            </div>
        );
    } else return null;
}

export default App;
