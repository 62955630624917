import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMapMarkerAlt, faPhone} from "@fortawesome/free-solid-svg-icons";

interface Props {
    lang: string;
}

export const ContactInfo:FC<Props> = ({lang}) => {
    return (
        <div className="contact-info col-l-4 col-12">
            <h2 className="h3 contact__heading">{lang === "en" ? "Contact us By Phone or Email" : "Kontakt oss via Telefon eller Epost"}</h2>
            <a href="tel:+4790803565" className="contact__phone contact__item">
                <FontAwesomeIcon icon="phone" /> +47 908 03 565
            </a>
            <a href="mailto:post@tysdalcamping.no" className="contact__mail contact__item">
                <FontAwesomeIcon icon="envelope"/> kontakt@tysdalcamping.no
            </a>
            <a href="https://goo.gl/maps/G3NRWsoCVdsza5qp9" className="contact__dest contact__item" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon="map-marker-alt" /> RyfylkeVeien 4251, 4137 Årdal
            </a>
        </div>
    )
}