import React, {FC} from "react";

interface Props {
    setPreference: (lang:string, curr:string) => void;
}

export const LangSelect:FC<Props> = ({setPreference}) => {
    return (
        <div className="page">
            <div className="landing">
                <div className="landing__hero">
                    <img src={"/bilder/hero1.jpg"} alt="Overlook of the Camping site"/>
                </div>
            </div>
            <div className="hero__content">
                <div className="hero__greet">
                    <h1 className="hero__primary">Velkommen til Tysdal Camping</h1>
                    <h2 className="h2">Please Select a Language, Vennligst velg et språk</h2>
                </div>
                <div className="lang-select">
                    <button className="lang-select__option" onClick={() => setPreference("no", "nok")}>
                        <img src={"/bilder/NO.svg"} alt="Norsk"/>
                        <p className="lang-select__label">Norsk</p>
                    </button>
                    <button className="lang-select__option" onClick={() => setPreference("en", "euro")}>
                        <img src={"/bilder/EN.svg"} alt="English"/>
                        <p className="lang-select__label">English</p>
                    </button>
                </div>
            </div>
        </div>
    )
}