import {useEffect, useState} from "react";
import {useAppState} from "../context/global";

export default function useFetchFacilities() {
    const [appState, setAppState]:any = useAppState();

    useEffect(() => {
        fetch("/priser.json")
            .then(response => response.json())
            .then(data => {
                setAppState(data);
            });
    }, []);

}