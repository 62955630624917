import React, {FC} from "react";

interface Props {
    lang: string;
}
export const Footer:FC<Props> = (props) => {
    return (
        <footer className="footer">
            <div className="footer__left">

            </div>
            <div className="footer__middle">

            </div>
            <div className="footer__right">

            </div>
        </footer>
    )
}