import React, {FC} from "react";
//data


//components
import {Hero} from "../components/globalComponents/Hero/Hero";
import {carousel as Carousel} from "../components/globalComponents/Carousel/Carousel";
import {CardList} from "../components/globalComponents/CardItems/CardList";
import {ContactForm} from "../components/pageComponents/Contact/ContactForm";
import {Map} from "../components/globalComponents/Map/Map";
import {ContactInfo} from "../components/pageComponents/Contact/ContactInfo";
import {useAppState} from "../context/global";


interface Props {
    lang: string;
    currency: string;
}

export const Home:FC<Props> = ({lang, currency}) => {
    let [appState]:any = useAppState();
    console.log(appState);
    const prices = appState;
    return (
        <div className="page homepage">
            <Hero lang={lang} fixed={true}/>
            <div className="page__wrapper">
                <main className="main--hero container--column">
                    <div className="main__about main__content">
                        <div className="about container--row--center">
                            <div className="about__info col-12">
                                <h2 className="h3--center">{lang === "en" ?(
                                    "About the Camping"
                                ) : (
                                    "Litt om Campingen"
                                )}</h2>
                                <p className="paragraph">
                                    {lang === "en" ? (
                                        "Tysdal Camping is a modest, calm, and relaxing camping place, its located near many of Stavangers tourist attractions. Have a small kiosk and is close to nearby shop with a travel time by car of 15 minutes"
                                    ) : (
                                        "Tysdal Camping er en enkel og rolig campingplass, som ligger nær veldig mange turist attraksjoner samt fine turstier. har liten kiosk og ligger ca. 15 minutter fra nærmeste butikk."
                                    )}
                                </p>
                                <p className="paragraph">
                                    {lang === "en" ? (
                                        "The location of Tysdal camping is in the valley at the end of tysdalsvannet, where the river goes out to the water, which gives the camping place a gorgeous view of the Norwegian nature. The camping got its only for visitors of the camping."
                                    ) : (
                                        "Ligger midt i dalen på enden av tysdalvatnet, hvor elven går ut i vannet. Noe som gir campingplassen en utrolig fin utsikt. Campingplassen har også en fin strand tilknuttet campingen for besøkende."
                                    )}
                                </p>
                                <p className="paragraph">
                                    {lang === "en" ? (
                                        "There is activities for kids on the camping place ranging from swings, beach-sport to games , We have boats people who stay at the camping are free to borrow. Where people can row out and fish, or just enjoy the scenery."
                                    ) : (
                                        "Det er aktiviteter for barn tilgjengelig på campingplassen. der er husker, sandkasse & spill. Vi har to typer båter for folk å låne fritt, en robåt som passer bra til å ro ut å fiske med eller bare nyte naturen & vi har en kano for store og små til å ro rundt i."
                                    )}
                                </p>
                                <p className="paragraph">
                                    {lang === "en" ? (
                                        "Toilets and Showers are located at the service building. The showers included with every stay, max 10minutes per person."
                                    ) : (
                                        "Toaletter og Dusjer er i servicebygget. Dusjene er gratis å bruke, max 10minutter per person."
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="main__showcase">
                        <Carousel/>
                    </div>
                    <div className="main__stays main__content">
                        {/*<h2 className="h1--center">{lang === "en" ? "Prices" : "Priser"}</h2>*/}
                        <CardList currency={currency} lang={lang} stays={prices.stay} extras={prices.extras}/>
                    </div>
                </main>
                <aside className="contact__content aside container--column">
                    <h2 className="h1--center">{lang === "en" ? "Contact Us" : "Kontakt oss"}</h2>
                    <div className="contact container--row">
                        <ContactForm lang={lang}/>
                        <ContactInfo lang={lang}/>
                    </div>
                    <div className="about__map col-12">
                        <Map lang={lang}/>
                    </div>
                </aside>
            </div>
        </div>
    )
}