import React, {FC} from "react";

interface Props {
    lang: string;
}

export const Map:FC<Props> = ({lang}) => {
    return (
        <iframe className="map"
            src="https://www.google.com/maps/embed/v1/place?q=tysdal+camping&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&zoom=10"
            width="100%" style={{height: "60vh"}} title="Google map" allowFullScreen
            aria-hidden="false"/>
    )
}

