import React, {FC} from "react";

interface Props {
    lang: string;
    currency: string;
    item: any;
    className: string;
}

export const ExtraItem:FC<Props> = ({lang, currency, item, className}) => {
    const data = item;
    return (
        <div className={className ? "card-item__" + className : "card-item"}>
            <img className="card-item__image" src={data.img} alt={lang === "no" ? data.name.no : data.name.en}/>
            <span className="card-item__title h3">{lang === "no" ? data.name.no : data.name.en}</span>
            <div className="card-item__description">
                <div className="card__item__infoWrapper">
                    <span className="card-item__label">{lang === "en" ? "Price: " : "Pris: "}</span>
                    <span className="card-item__info">{currency === "euro" ? `${data.euro}£ / ${data.nok},-` : `${data.nok},- / ${data.euro}£`}</span>
                </div>
            </div>
        </div>
    )
}