import React, {FC, useState} from "react";
import {Link as Scroll} from "react-scroll/modules";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

//media
interface Props {
    lang: string;
    setPref: (l:string, c:string) => void;
}

function checkWindowSize() {
    return window.innerWidth > 720;
}

function checkNavBarOffset() {
    return (window.pageYOffset < (window.innerHeight * 0.8) - 85);
}

export const Navigation:FC<Props> = ({lang, setPref}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [fadeNav, setFadeNav] = useState(true);

    window.onresize = () => setShowMenu(checkWindowSize);
    window.onload = () => setShowMenu(checkWindowSize)
    window.onscroll = () => setFadeNav(checkNavBarOffset());

    return (
        <nav className="nav" style={{backgroundColor: fadeNav ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.95)"}}>
            <div className="nav__content container--row">
                <div className="nav__left col-auto">
                    {showMenu && (
                        <div className="nav__links">
                            <Scroll className="nav__link" to="main__about" smooth={true}>{lang === "en" ? "About" : "Om" +
                                " oss"}</Scroll>
                            <Scroll className="nav__link" to="main__stays" smooth={true}>{lang === "en" ? "Prices" : "Priser"}</Scroll>
                            <Scroll className="nav__link" to="contact__content" smooth={true}>{lang === "en" ? "Contact us" : "Kontakt oss"}</Scroll>
                        </div>
                    )}
                    <button className="nav__toggle" onClick={() => setShowMenu(!showMenu)}><FontAwesomeIcon icon="bars"/></button>
                </div>
                <div className="nav__right">
                    <button className="nav__lang" onClick={() => setPref("en", "euro")}><img src={"/bilder/EN.svg"} alt="English"/></button>
                    <button className="nav__lang" onClick={() => setPref("no", "nok")}><img src={"/bilder/NO.svg"} alt="Norsk"/></button>
                </div>
            </div>
        </nav>
    )
}